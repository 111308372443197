<template>
  <div class="status">
    <h3 class="popup-title">
      Statut
    </h3>

    <div class="servers">
      <span
        :class="{ active: selServ === 'NA' }"
        @click="setServ('NA')"
      >
        NA</span>
      <span
        :class="{ active: selServ === 'EU' }"
        @click="setServ('EU')"
      >
        EU</span>
      <span
        :class="{ active: selServ === 'SEA' }"
        @click="setServ('SEA')"
      >
        SEA</span>
      <span
        :class="{ active: selServ === 'TW' }"
        @click="setServ('TW')"
      >
        TW</span>
      <span
        :class="{ active: selServ === 'CN' }"
        @click="setServ('CN')"
      >
        CN</span>
      <span
        :class="{ active: selServ === 'KR' }"
        @click="setServ('KR')"
      >
        KR</span>
      <span
        :class="{ active: selServ === 'JP' }"
        @click="setServ('JP')"
      >
        JP</span>
    </div>

    <div
      v-if="sNow === null"
      class="loading"
    >
      Chargement…
    </div>
    <table
      v-else
      ref="refDetails"
      class="details"
    >
      <tbody>
        <tr>
          <td>Heure serveur :&nbsp;</td>
          <td>{{ sTime }} <small>({{ sTZ }})</small></td>
        </tr>
        <tr>
          <td>Reset quotidien :&nbsp;</td>
          <td>Dans {{ sReset }}</td>
        </tr>
        <tr>
          <td>Abyss/Dirac/QS :&nbsp;</td>
          <td>{{ sAbyss }}</td>
        </tr>
        <tr>
          <td>Open World :&nbsp;</td>
          <td>Reset dans {{ sOW }}</td>
        </tr>
        <tr v-if="sMT">
          <td>Mise à jour :&nbsp;</td>
          <td>{{ sMT }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { DateTime } from 'luxon'

const mtDate = {
  day: 28,
  month: 11,
  year: 2024,
}

const selServ = ref('EU')
const sNow = ref(null)
const servers = ref({
  EU: {
    timezone: 'UTC+1',
    mt: {
      dur: 7,
      hour: 5,
      ...mtDate,
    },
  },
  NA: {
    timezone: 'UTC-5',
    mt: {
      dur: 7,
      hour: 23,
      day: mtDate.day - 1, // -1 because it's before midnight
      month: mtDate.month,
      year: mtDate.year,
    },
  },
  SEA: {
    timezone: 'UTC+8',
    mt: {
      dur: 6,
      hour: 10,
      ...mtDate,
    },
  },
  KR: {
    timezone: 'UTC+9',
    mt: {
      dur: 6,
      hour: 11,
      ...mtDate,
    },
  },
  JP: {
    timezone: 'UTC+9',
    mt: {
      dur: 7,
      hour: 9,
      ...mtDate,
    },
  },
  TW: {
    timezone: 'UTC+8',
    mt: {
      dur: 6,
      hour: 10,
      ...mtDate,
    },
  },
  CN: {
    timezone: 'UTC+8',
    mt: null,
  },
})

const refDetails = ref()

const sMT = computed(() => {
  const mt = servers.value[selServ.value].mt
  if (sNow.value === null || mt === null || mt.day === null) return null

  // set countdown
  const r = DateTime.fromObject({
    day: mt.day,
    month: mt.month,
    year: mt.year,
    hour: mt.hour,
    minute: 0,
    seconds: 0,
  }, {
    zone: servers.value[selServ.value].timezone,
  })

  const diff = r.diff(sNow.value, ['hours', 'minutes'])

  if (diff.minutes < 0) {
    // we've past it, change diff to duration
    const nR = r.plus({ hours: mt.dur })
    const nDiff = nR.diff(sNow.value, ['hours', 'minutes'])

    if (nDiff.minutes < 0) return null

    return 'reste ' + dDiff(nDiff)
  }

  return 'dans ' + dDiff(diff)
})

const sTime = computed(() => {
  if (sNow.value === null) return '..:..:..'

  return sNow.value.toFormat('HH:mm:ss')
})

const sTZ = computed(() => {
  return servers.value[selServ.value].timezone
})

const sReset = computed(() => {
  if (sNow.value === null) return '..h..'

  let r = DateTime.fromObject({ hour: 4, minute: 0, seconds: 0 }, { zone: servers.value[selServ.value].timezone })
  if (sNow.value.hour >= 4) r = r.plus({ days: 1 })

  const diff = r.diff(sNow.value, ['hours', 'minutes'])
  return dDiff(diff)
})

const sAbyss = computed(() => {
  if (sNow.value === null) return '..... dans ..h..'

  const n = sNow.value
  let o1 = DateTime.fromObject({ weekday: 1, hour: 15, minute: 0, seconds: 0 }, { zone: servers.value[selServ.value].timezone })
  const e1 = o1.plus({ hours: 55 })
  const o2 = DateTime.fromObject({ weekday: 5, hour: 15, minute: 0, seconds: 0 }, { zone: servers.value[selServ.value].timezone })
  const e2 = o2.plus({ hours: 55 })

  let diff
  if (n.weekday === 7) o1 = o1.plus({ days: 7 })

  if (n >= o1 && n < e1) {
    diff = e1.diff(n, ['hours', 'minutes'])
    return 'Ferme dans ' + dDiff(diff)
  } else if (n >= o2 && n < e2) {
    diff = e2.diff(n, ['hours', 'minutes'])
    return 'Ferme dans ' + dDiff(diff)
  } else if (n >= e1 && n < o2) {
    diff = o2.diff(n, ['hours', 'minutes'])
    return 'Ouvre dans ' + dDiff(diff)
  } else {
    diff = o1.diff(n, ['hours', 'minutes'])
    return 'Ouvre dans ' + dDiff(diff)
  }
})

const sOW = computed(() => {
  if (sNow.value === null) return '..h..'

  const n = sNow.value
  let r1 = DateTime.fromObject({ weekday: 1, hour: 4, minute: 0, seconds: 0 }, { zone: servers.value[selServ.value].timezone })
  const r2 = DateTime.fromObject({ weekday: 4, hour: 4, minute: 0, seconds: 0 }, { zone: servers.value[selServ.value].timezone })
  const r3 = DateTime.fromObject({ weekday: 6, hour: 4, minute: 0, seconds: 0 }, { zone: servers.value[selServ.value].timezone })

  let diff
  if (n.weekday > 5) r1 = r1.plus({ days: 7 })

  if (n < r3) diff = r3.diff(n, ['hours', 'minutes'])
  if (n < r2) diff = r2.diff(n, ['hours', 'minutes'])
  if (n < r1) diff = r1.diff(n, ['hours', 'minutes'])

  return dDiff(diff)
})

onMounted(() => {
  selServ.value = localStorage.getItem('server') || 'EU'

  tick()
  setInterval(tick, 1000)
})

const dDiff = (diff) => {
  let d = ''
  let h = diff.hours
  let m = Math.floor(diff.minutes + 1)

  if (m === 60) { // because of that +1
    h += 1
    m = 0
  }

  if (h > 48) { // display days if over 48h
    d = Math.floor(h / 24) + 'j '
    h -= Math.floor(h / 24) * 24
  }

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m

  return d + h + 'h' + m
}

const tick = () => {
  sNow.value = DateTime.local().setZone(servers.value[selServ.value].timezone)
}

const setServ = (s) => {
  localStorage.setItem('server', s)
  refDetails.value.classList.add('out')

  setTimeout(() => {
    selServ.value = s
    tick()
    refDetails.value.classList.remove('out')
  }, 300)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.status {
  border-image-slice: 4;
  border-image-source: url('/img/border-popup.svg');
  border-style: solid;
  border-width: 4px;
  margin-bottom: 2em;
  padding: 4px;

  .popup-title {
    background-color: #fff;
    color: #000;
    font-size: medium;
    margin: 0;
    text-align: center;
  }

  .servers {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;

    span {
      cursor: pointer;
      flex-grow: 1;
      font-size: .9em;
      padding: 4px 0 0;
      text-align: center;

      &:active {
        background: #00d6fe;
      }

      &.active {
        animation: get-active 0.6s;
        background: #fff;
        border-bottom: 2px solid #00d6fe;
        color: #000;
        transition: color 0.6s;
      }
    }
  }

  .loading {
    font-style: italic;
    font-weight: bold;
    max-width: calc(100% - 24px);
    text-align: center;
  }

  .loading,
  .details {
    background-color: rgba(0, 0, 0, 0.6);
    background-image: url('/img/grid.png');
    background-repeat: repeat;
    border: 2px solid rgba(0, 0, 0, 0.8);
    box-shadow: 5px 4px rgba(0, 0, 0, 0.2);
    margin-top: 2px;
    overflow: hidden;
    padding: 5px 10px;
    width: 100%;

    &.out {
      td {
        animation: slide-fade-out 0.6s ease-in-out;
      }
    }

    td {
      animation: slide-fade-in 0.5s ease-in-out;

      &:first-child {
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
        width: 106px;

        @media screen and (min-width: 380px) {
          width: 135px;
        }
      }

      &:not(:first-child) {
        font-family: monospace;
        font-size: 1.1em;
      }
    }
  }

  @keyframes get-active {
    0% { background-color: #00d6fe; }
    100% { background-color: #fff; }
  }

  @keyframes slide-fade-in {
    0% {
      opacity: 0;
      transform: translateX(25px);
    }
    50% {
      opacity: 0;
      transform: translateX(25px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes slide-fade-out {
    0% {
      opacity: 1;
      transform: translateX(0px);
    }
    25% {
      opacity: 0;
      transform: translateX(-25px);
    }
    100% {
      opacity: 0;
      transform: translateX(-25px);
    }
  }
}
</style>
